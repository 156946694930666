<template>
    <div class="RoleMenu">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button
                size="mini"
                :disabled="editing || !hasPrivilege('menu.system.role.deptGroup.menu.edit')"
                @click="editing = true"
                >编辑</el-button
            >
            <el-button type="primary" size="mini" :disabled="!editing" @click="handleSave">保存</el-button>
            <el-button size="mini" :disabled="!editing" @click="handleCancel">取消</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-checkbox-group v-model="bindCodes">
                <el-table
                    id="menu"
                    border
                    stripe
                    :data="tableData"
                    size="medium"
                    :highlight-current-row="true"
                    max-height="560"
                    row-key="code"
                    default-expand-all
                    :tree-props="{ children: 'list' }"
                >
                    >
                    <el-table-column label="序号" type="index" width="80" align="center" fixed="left" />
                    <el-table-column width="200" label="菜单名称" prop="name" />
                    <el-table-column width="80" label="显示" align="right">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (checked) => {
                                        checkboxChange(checked, scope.row.privs['show'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['show']"
                                :label="scope.row.privs['show']"
                                >显示
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['show'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="查询">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (checked) => {
                                        checkboxChange(checked, scope.row.privs['open'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['open']"
                                :label="scope.row.privs['open']"
                                >查询
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['open'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="新建">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['create'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['create']"
                                :label="scope.row.privs['create']"
                            >
                                新建
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['create'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="编辑">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['edit'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['edit']"
                                :label="scope.row.privs['edit']"
                                >编辑
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['edit'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="审核">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['review'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['review']"
                                :label="scope.row.privs['review']"
                            >
                                审核
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['review'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="删除">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['delete'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['delete']"
                                :label="scope.row.privs['delete']"
                            >
                                删除
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['delete'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="导出">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['export'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['export']"
                                :label="scope.row.privs['export']"
                            >
                                导出
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['export'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="出/入库">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['stock'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['stock']"
                                :label="scope.row.privs['stock']"
                            >
                                出入库
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['stock'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="110" label="充值/退款">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['rechargeRefund'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['rechargeRefund']"
                                :label="scope.row.privs['rechargeRefund']"
                            >
                                充值/退款
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['rechargeRefund'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="领/退">
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="
                                    (e) => {
                                        checkboxChange(e, scope.row.privs['reserveRefund'], scope.row);
                                    }
                                "
                                v-if="editing && scope.row.privs['reserveRefund']"
                                :label="scope.row.privs['reserveRefund']"
                            >
                                领/退
                            </el-checkbox>
                            <div v-if="showCheck(scope.row.privs['reserveRefund'])">
                                <span class="check"><i class="el-icon-check" /></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-checkbox-group>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'MenuSetting',
    props: ['form'],
    data() {
        return {
            tableData: [],
            bindCodes: [],
            backUp: [],
            url: {
                menuPrivileges: '/system/role/menuPrivs',
                save: '/system/deptGroupRole/updateOpenTypeByPrivilegeCodes',
            },
            editing: false,
            //key:上级权限code通过+号进行拼接，value：关联的下级权限数组
            appendParentCodeStr2ChildrenCodeArrMap: new Map(),
            //key:下级权限code，value：关联的上级权限数组
            childrenCode2ParentCodeArrMap: new Map(),
        };
    },
    mounted() {
        const _this = this;
        new Promise((resolve, reject) => {
            UrlUtils.AllMenus(this, (menus) => {
                resolve(menus);
            });
        }).then((menus) => {
            UrlUtils.AllMenusPrivs(this, (privs) => {
                menus.forEach((v, i) => {
                    const _flag = v['privilegeFlag'];
                    v.privs = {
                        open: privs[_flag + '.open'],
                        show: privs[_flag + '.show'],
                    };
                    (v.list || []).forEach((v1, i1) => {
                        const _flag1 = v1['privilegeFlag'];
                        v1.privs = {
                            create: privs[_flag1 + '.create'],
                            delete: privs[_flag1 + '.delete'],
                            edit: privs[_flag1 + '.edit'],
                            export: privs[_flag1 + '.export'],
                            open: privs[_flag1 + '.open'],
                            review: privs[_flag1 + '.review'],
                            show: privs[_flag1 + '.show'],
                            stock: privs[_flag1 + '.stock'],
                            rechargeRefund: privs[_flag1 + '.rechargeRefund'],
                            reserveRefund: privs[_flag1 + '.reserveRefund'],
                        };
                    });
                });
                _this.tableData = menus;
                this.tableData.forEach((parentPrivs) => {
                    const appendParentPrivsCodeStr = Object.values(parentPrivs.privs).join('+');
                    this.appendParentCodeStr2ChildrenCodeArrMap.set(
                        appendParentPrivsCodeStr,
                        parentPrivs.list.flatMap((childrenPrivs) => {
                            return Object.values(childrenPrivs.privs).filter((item) => item !== undefined);
                        })
                    );
                    parentPrivs.list.forEach((childrenPrivs) => {
                        Object.values(childrenPrivs.privs).forEach((childPrivsCode) => {
                            if (childPrivsCode !== undefined) {
                                this.childrenCode2ParentCodeArrMap.set(
                                    childPrivsCode,
                                    Object.values(parentPrivs.privs)
                                );
                            }
                        });
                    });
                });
            });
        });
        if (!_this.form.code) {
            UrlUtils.QueryRemote(_this, '/system/menu/findGroupCanList', (rst) => {
                const _a = [];
                for (const i in rst) {
                    _a.push(rst[i]);
                }

                _this.bindCodes = _a;
                _this.backUp = JSON.parse(JSON.stringify(_this.bindCodes));
            });
        } else {
            UrlUtils.QueryRemote(this, this.url.menuPrivileges + '?roleCode=' + this.form.code, (rst) => {
                const _a = [];
                for (const i in rst) {
                    _a.push(rst[i]);
                }

                _this.bindCodes = _a;
                _this.backUp = JSON.parse(JSON.stringify(_this.bindCodes));
            });
        }
    },
    methods: {
        checkboxChange(checked, privsCode, row) {
            if (row.level === 0) {
                //上级权限处理
                const appendParentPrivsCodeStr = Array.from(this.appendParentCodeStr2ChildrenCodeArrMap.keys()).find(
                    (appendParentPrivsCodeStr) => appendParentPrivsCodeStr.indexOf(privsCode) >= 0
                );
                // 获取到一级的Map中点击的那个数组
                const childrenPrivsCodeArr = this.appendParentCodeStr2ChildrenCodeArrMap.get(appendParentPrivsCodeStr);
                //    上级权限数据，判断所有上级权限是否都勾选了，如果勾选了则自动勾选全部下级权限
                if (checked) {
                    const allParentPrivsCodeArr = appendParentPrivsCodeStr.split('+') || [];
                    const allMatchedFlag = allParentPrivsCodeArr.every(
                        (parentPrivsCode) => this.bindCodes.indexOf(parentPrivsCode) >= 0
                    );
                    if (allMatchedFlag) {
                        //    上级权限都勾选了，勾选关联的全部下级权限
                        this.bindCodes.push(...childrenPrivsCodeArr);
                    }
                    this.bindCodes = Array.from(new Set(this.bindCodes));
                } else {
                    // 取消全部勾选
                    childrenPrivsCodeArr.forEach((childPrivsCode) => {
                        var flag = this.bindCodes.indexOf(childPrivsCode);
                        flag >= 0 && this.bindCodes.splice(flag, 1);
                    });
                }
            } else {
                // 二级勾选获取当前那一项将一级的权限Code加入this.bindCodes的数组中
                if (checked) {
                    this.childrenCode2ParentCodeArrMap.get(privsCode).forEach((parentPrivsCode) => {
                        if (this.bindCodes.indexOf(parentPrivsCode) === -1) {
                            this.bindCodes.push(parentPrivsCode);
                        }
                    });
                }
            }
        },
        handleSave() {
            const _this = this;
            const checked = this.bindCodes.filter((a) => !this.backUp.includes(a));
            const unchecked = this.backUp.filter((a) => !this.bindCodes.includes(a));
            UrlUtils.PatchRemote(this, this.url.save, { checked, unchecked }, null, () => {
                _this.$message.success('保存成功');
                _this.editing = false;
                _this.backUp = JSON.parse(JSON.stringify(_this.bindCodes));
            });
        },
        handleCancel() {
            this.bindCodes = JSON.parse(JSON.stringify(this.backUp));
            this.editing = false;
        },
        showCheck(flagCode) {
            return !this.editing && this.bindCodes.find((a) => a == flagCode);
        },
    },
};
</script>

<style scoped>
.RoleMenu .check {
    display: block;
    font-size: 32px;
    transition: color 0.15s linear;
    color: #13ce66;
}
</style>
